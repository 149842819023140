<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script setup lang="ts">
import type { Wallet } from 'solana-wallets-vue/dist/types'
import { evaClose } from '@quasar/extras/eva-icons'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import { useWallet } from 'solana-wallets-vue'
import { PhantomWalletName, SolflareWalletName } from 'wallets'
import { OKXWalletName } from 'wallets/adapters/OKXWalletAdapter'

import cloverSvg from '~/assets/img/wallets/clover.svg'
import ledgerDarkSvg from '~/assets/img/wallets/ledger.svg'
import mathWalletDarkSvg from '~/assets/img/wallets/mathwallet.svg'
import { shortenAddress } from '~/utils'

defineProps({
  onlyDisconnect: Boolean,
})

type WalletSettings = {
  priority: Record<string, number>
  icons: Record<string, string>
  darkIcons: Record<string, string>
  deepLinks: Record<string, string>
}

// Customize wallets
const settings: WalletSettings = {
  priority: {
    solflare: 10,
    phantom: 20,
    sollet: 5,
    nufi: 5,
  },
  icons: {
    clover: cloverSvg,
  },
  darkIcons: {
    ledger: ledgerDarkSvg,
    mathwallet: mathWalletDarkSvg,
  },
  deepLinks: {
    phantom: 'https://phantom.app/ul/browse/{uri}?ref={ref}',
    solflare: 'solflare://ul/v1/browse/{uri}?ref={ref}',
    okxwallet: 'okx://wallet/dapp/details?dappUrl={host}',
  },
}

const route = useRoute()

const { userAgent, isWebView, isMobileOs } = useMobileDetect()

const icons = {
  close: evaClose,
}

function isActiveWallet(w: Wallet) {
  return w.readyState === WalletReadyState.Installed || w.readyState === WalletReadyState.Loadable
}

const wallet = useWallet()
const { connected, connecting } = wallet

const dialog = ref(false)
const connectingTimer = ref<any>(0)

function cancelTimer() {
  if (connectingTimer.value > 0) {
    clearTimeout(connectingTimer.value)
  }
}

async function select(w: Wallet) {
  cancelTimer()
  await wallet.select(w.adapter.name)
  dialog.value = false
  connectingTimer.value = setTimeout(() => {
    connecting.value = false
  }, 10000)
  await wallet.connect()
}

const isMobile = computed(() => isMobileOs.value && !isWebView.value)

const webViewWallet = ref()
if (isWebView.value) {
  const ua = String(userAgent.value).toLowerCase()
  if (ua.includes('phantom')) {
    webViewWallet.value = PhantomWalletName
  // @ts-expect-error solflare is valid attr
  } else if (window.solflare) {
    webViewWallet.value = SolflareWalletName
  } else if (/okapp/i.test(ua)) {
    webViewWallet.value = OKXWalletName
  }
}

type ExtendedWallet = Wallet & { icon: string, darkIcon: string, deepLink: string }
const walletAddress = computed(() => wallet.publicKey.value?.toBase58() ?? '')
const walletShortAddress = computed(() => shortenAddress(walletAddress.value, 4))
const wallets = computed<ExtendedWallet[]>(() =>
  [...wallet.wallets.value as ExtendedWallet[]]
    .map((w) => {
      const key = w.adapter.name.toLowerCase().replace(/\s/g, '')
      // only show deep links on mobile
      if (isMobile.value && settings.deepLinks[key]) {
        w.deepLink = settings.deepLinks[key]!
          .replace('{uri}', encodeURIComponent(`${location.href}?wallet=${w.adapter.name}`))
          .replace('{ref}', encodeURIComponent(location.origin))
          .replace('{host}', location.host)
      }
      return w
    })
    .sort((a, b) => {
      const aPriority = settings.priority[a.adapter.name.toLowerCase()] ?? 1
      const bPriority = settings.priority[b.adapter.name.toLowerCase()] ?? 1
      return (
        bPriority
        - aPriority
        + ((isActiveWallet(b) ? 1 : 0) - (isActiveWallet(a) ? 1 : 0))
      )
    }),
)

const computedWallets = computed(() => {
  if (webViewWallet.value) {
    return wallets.value.filter(w => w.adapter.name === webViewWallet.value)
  }
  return isMobile.value ? wallets.value.filter(w => !!w.deepLink) : wallets.value
})

watch(connected, () => {
  if (connected.value) {
    cancelTimer()
  }
})

watch(() => route.query?.wallet, (w) => {
  if (w) {
    webViewWallet.value = w
  }
}, { immediate: true })

function disconnect() {
  dialog.value = false
  wallet.disconnect()
}

if (webViewWallet.value) {
  wallet.select(webViewWallet.value)
  wallet.connect()
}

function show() {
  if (!connected.value && computedWallets.value.length === 1 && computedWallets.value[0]) {
    select(computedWallets.value[0])
  } else {
    dialog.value = true
  }
  Analytics.track({ event: 'Connect wallet', action: 'wallet.connect', label: 'Connect wallet' })
}

function ok() {
  dialog.value = false
}
</script>

<template>
  <q-btn
    v-if="connected && !onlyDisconnect"
    v-bind="$attrs"
    :class="$style.btn"
    :ripple="false"
    unelevated
    color="secondary"
    text-color="black"
    @click="show"
  >
    {{ walletShortAddress }}
  </q-btn>

  <q-btn
    v-else-if="connected && onlyDisconnect"
    v-bind="$attrs"
    :class="$style.btn"
    :ripple="false"
    unelevated
    color="secondary"
    text-color="black"
    @click="disconnect"
  >
    {{ $t('wallet.disconnect') }}
  </q-btn>

  <q-btn
    v-else
    v-bind="$attrs"
    :class="$style.btn"
    unelevated
    :ripple="false"
    :loading="connecting"
    color="secondary"
    text-color="black"
    @click="show"
  >
    {{ $t('wallet.connect') }}
  </q-btn>

  <q-dialog
    v-model="dialog"
    transition-duration="150"
    transition-show="fade"
    transition-hide="fade"
    class="wallets-list-dialog"
  >
    <q-card v-if="connected" :dark="isDark">
      <q-card-section class="relative-position">
        <div class="text-h6 text-center">
          {{ $t('stakeBox.yourWallet') }}
        </div>
        <q-btn
          padding="md"
          color="transparent"
          text-color="primary-gray"
          unelevated
          class="absolute-right"
          :icon="icons.close"
          size="md"
          @click="ok"
        />
      </q-card-section>
      <q-separator />
      <q-card-section class="row items-center">
        <copy-to-clipboard :text="walletAddress" />
        {{ walletAddress }}
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="q-gutter-md row justify-between">
          <q-btn outline rounded @click="disconnect">
            {{ $t('wallet.disconnect') }}
          </q-btn>
          <q-btn outline rounded @click="ok">
            {{ $t('common.ok') }}
          </q-btn>
        </div>
      </q-card-section>
    </q-card>

    <q-card v-else class="wallet-connect-card" :dark="isDark">
      <q-card-section>
        <div class="text-h6">
          {{ $t('wallet.connectTo') }}
        </div>
        <q-btn
          padding="md"
          text-color="primary-gray"
          unelevated
          class="absolute-right"
          :icon="icons.close"
          :dark="isDark"
          size="md"
          @click="ok"
        />
      </q-card-section>
      <q-separator />
      <q-card-section style="max-height: 80vh" class="scroll">
        <q-table
          grid
          :rows="computedWallets"
          :dark="isDark"
          row-key="name"
          hide-pagination
          hide-header
          :rows-per-page-options="[100]"
        >
          <template #item="{ row: w }">
            <div :key="`wallet-${w.adapter.name}`" class="col-12 col-md-6">
              <q-item
                clickable
                class="bg-gray-5"
                :dark="isDark"
                :href="w.deepLink"
                @click="w.deepLink ? true : select(w)"
              >
                <q-item-section>
                  <b>{{ w.adapter.name }}</b>
                  <div
                    class="text-light-gray text-caption full-width text-no-wrap"
                    style="text-overflow: ellipsis; overflow: hidden"
                  >
                    {{ w.adapter.url }}
                  </div>
                </q-item-section>
                <q-item-section avatar>
                  <q-avatar square>
                    <img :src="w.adapter.icon" :alt="w.adapter.name">
                  </q-avatar>
                </q-item-section>
              </q-item>
            </div>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="scss">
.wallet-connect-card {
  overflow: hidden;
  .q-item {
    border: 1px solid $grey-2;
    margin: 3px;
    b {
      font-weight: 500;
    }
    &:hover {
      border-color: #e8e8e8;
    }
  }
}
</style>

<style lang="scss" module>
.btn.btn {
  min-width: 172px;
  width: max-content;
  height: 54px;
  white-space: nowrap;
  flex-wrap: nowrap;

  img {
    height: 0.6em;
    margin-right: 0.2em;
  }
}
</style>
